<template>
	<div class="view">
		<b-row class="main" align-v="center">
			<b-col>
				<b-row align-h="center">
					<b-col>존재하지 않는 페이지입니다.</b-col>
				</b-row>
				<b-row>&nbsp;</b-row>
				<router-link to="/home">
					<b-row align-h="center" align-v="center">
						<b-col>홈페이지로 돌아가기</b-col>
					</b-row>
				</router-link>
			</b-col>
		</b-row>
		<FeedbackIcon />
		<Footer />
	</div>
</template>

<script>
import FeedbackIcon from '@/components/FeedbackIcon';
import Footer from '@/components/Footer';

export default {
	name: 'NotFound',
	metaInfo: {
		// 페이지 제목 설정
		title: '404',
		titleTemplate: '%s | NOLGONG SCHOOL',
		meta: [
			// SEO 설정
			{
				name: 'description',
				content:
					'학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소> | ‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'description',
			},
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
		],
	},
	components: { FeedbackIcon, Footer },
};
</script>

<style scoped></style>
